<template>
    <div class="userProgress mx-auto container pt-12 pb-16">
        <loading-capsule :loaded="requiredContentHasLoaded" spinner-margin-top="10rem">
            <div class="max-w-base mx-auto" v-if="requiredContentHasLoaded">
                <div class="text-center">
                    <p class="userProgress__title">{{ $t('PROGRESS.TITLE') }}</p>
                </div>

                <div class="pt-12 text-center">
                    <p class="userProgress__subTitle pb-2">{{ $t('PROGRESS.DAILY_STREAK') }}</p>
                    <daily-streak
                        :streak="streakLength"
                        :has-completed-today="dailyStreakIncludesToday"/>
                </div>

                <div class="pt-12 text-center">
                    <p class="userProgress__subTitle pb-4">{{ $t('PROGRESS.PROGRESS') }}</p>
                    <p v-text="courseName"/>
                    <progress-bar :progress="coursePercentage" :show-percentage="true"/>
                    <div class="py-8">
                        <words-and-phrases-badge :count="wordsLearned"/>
                    </div>
                </div>

                <div class="pt-4 w-40 mx-auto text-center">
                    <p class="userProgress__subTitle pb-4">{{ $t('PROGRESS.UNITS_COMPLETED') }}</p>
                </div>
                <div class="max-w-base mx-auto">
                    <div v-if="hasCompletedLessons">
                        <completed-lessons-grid/>
                    </div>
                    <div v-else class="text-center text-xs">
                        <router-link class="link" :to="{ name: 'app.dashboard'}">
                            {{ $t('PROGRESS.UNITS_COMPLETED_NONE_CTA') }}
                        </router-link>
                    </div>
                </div>
            </div>
        </loading-capsule>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import ProgressBar from '@/components/ProgressBar';
    import LoadingCapsule from '@/components/structure/LoadingCapsule';
    import DailyStreak from '@/components/partials/UserProgress/DailyStreak';
    import WordsAndPhrasesBadge from '@/components/partials/UserProgress/WordsAndPhrasesBadge';
    import CompletedLessonsGrid from '@/components/partials/UserProgress/CompletedLessonsGrid';

    export default {
        name      : 'Progress',
        computed  : {
            ...mapGetters({
                wordsLearned            : 'Progress/getTotalWordsLearnedCount',
                courseName              : 'Course/getCourseName',
                coursePercentage        : 'Course/getCourseCompletionPercentage',
                dailyStreakIncludesToday: 'DailyProgress/getHasDailyProgressToday',
                streakLength            : 'DailyProgress/getStreakLength',
                userProgressFetched     : 'Progress/userProgressHasFetched',
                dailyProgressFetched    : 'DailyProgress/dailyProgressHasFetched',
                courseFetched           : 'Course/courseHasFetched',
                lessonsCompleted        : 'Course/getAllCompletedLessons',
            }),
            requiredContentHasLoaded() {
                return this.userProgressFetched
                    && this.dailyProgressFetched
                    && this.courseFetched;
            },
            hasCompletedLessons() {
                return (this.lessonsCompleted || []).length > 0;
            },
        },
        components: {
            LoadingCapsule,
            ProgressBar,
            WordsAndPhrasesBadge,
            CompletedLessonsGrid,
            DailyStreak,
        },
    };
</script>
