<template>
    <div class="circleBadge circleBadge--green">
        <div class="circleBadge__container">
            <p class="circleBadge__count" v-text="count"/>
            <div class="w-48 mx-auto">
                <p class="circleBadge__title"
                   v-text="$t('PROGRESS.WORDS_AND_PHRASES_LEARNED')"/>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'WordsAndPhrasesBadge',
        props: {
            count: {
                type    : Number,
                required: true,
            },
        },
    };
</script>
