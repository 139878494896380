<template>
    <div>
        <div class="flex flex-wrap justify-around">
            <div class="w-20 p-1 text-center cursor-pointer"
                 v-for="lesson in lessonsCompleted"
                 :key="lesson.id">
                <lesson-icon
                    class="mx-auto"
                    :completion="0"
                    :image-src="getImageSrcForLesson(lesson)"
                    type="xs"/>
                <p class="dashboard__chooseUnitName" v-text="lesson.name"/>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import LessonIcon from '@/components/partials/Dashboard/LessonIcon';

    export default {
        name      : 'CompletedLessonsGrid',
        computed  : {
            ...mapGetters({
                lessonsCompleted: 'Course/getAllCompletedLessons',
            }),
        },
        methods   : {
            /**
             * Get the image src for the given lesson.
             *
             * @param {*} lesson
             *
             * @return {string|null}
             */
            getImageSrcForLesson(lesson) {
                return lesson.entity.hasImage()
                    ? lesson.entity.getImageSrc()
                    : null;
            },
        },
        components: {
            LessonIcon,
        },
    };
</script>
